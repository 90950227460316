import React, { useEffect, useRef } from 'react';
import { string, bool } from 'prop-types';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  Heading,
  Button,
  Text,
  Box,
  Image,
  Center,
  // hooks
  useDisclosure,
} from '@chakra-ui/react';

import { IMG_COVID } from '@/constants/assets';
import { BUTTON_PROPS } from '@/constants/colors';
import txt from './locales';

/**
 * Function to render Covid Notification
 * @returns {JSX.Element | null}
 */
function CovidProtocol({ visible, lang }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isShowed = useRef(false);

  const handleCloseModal = () => {
    onClose();
  };

  useEffect(() => {
    if (visible && !isShowed.current) {
      setTimeout(() => {
        onOpen();
      }, 2000);
      isShowed.current = true;
    }
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent width="90%" maxWidth="400px">
        <ModalBody fontSize="sm" padding="0">
          <Box textAlign="center" padding="8px 24px">
            <Heading color="alternativeDark" marginTop="16px" fontWeight="normal">
              Protokol Kesehatan
            </Heading>
            <Text fontSize="sm">
              Demi kenyamanan bersama, acara pernikahan kami memberlakukan protokol kesehatan
              sebagai berikut:
            </Text>
          </Box>
          <Center>
            <Image marginTop="-8px" src={IMG_COVID[lang]} width="100%" />
          </Center>
          <Text padding="8px 24px">
            *) Catatan: <br />
            Mohon maaf, bilamana saat ini Anda dalam keadaan kurang sehat, dimohon untuk menunda
            kehadiran terlebih dahulu. Demi kenyamanan bersama, kami sangat memaklumi dan akan tetap
            menjalin silaturrahmi melalui media online. Atas perhatiannya, kami ucapkan terima
            kasih.
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button
            size="sm"
            color="white"
            bgColor="alternativeDark"
            onClick={handleCloseModal}
            {...BUTTON_PROPS}
          >
            {txt.understand[lang]}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

CovidProtocol.propTypes = {
  lang: string.isRequired,
  visible: bool.isRequired,
};

export default CovidProtocol;
